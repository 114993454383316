<template>
  <div class="wrapper-box">
    <el-card>
      <el-table ref="multiTable" :data="apprNeedData" border v-loading="loading">
        <el-table-column label="单据编号" prop="form_no" width="120px" />
        <el-table-column label="发起人说明" prop="appr_inst_info" :show-overflow-tooltip="true" />
        <el-table-column label="审批时间" prop="create_time" :formatter="formatDate" width="280px" />
        <el-table-column label="操作" align="center" width="120px">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-right" @click="goAppr(scope.row.appr_inst_url)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { apprAPI } from '@api/modules/appr';
import UrlEncode from '@assets/js/UrlEncode';
export default {
  name: 'boardAllLeft',
  data() {
    return {
      apprNeedData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getAllApprove();
    },
    // 获取待审批记录（全部）
    getAllApprove() {
      get(apprAPI.getIApprove, { limit: 100 })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.apprNeedData = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    goAppr(val) {
      let newArr = val.split('?');
      let newArr2 = newArr[1].split('&');
      let prop1 = newArr2[0].split('=');
      let prop2 = newArr2[1].split('=');
      let formObj = {};
      formObj[prop1[0]] = prop1[1];
      formObj[prop2[0]] = prop2[1];

      this.jump(newArr[0], { key: UrlEncode.encode(JSON.stringify(formObj)) });
    }
  }
};
</script>

<style scoped></style>
